/* tslint:disable */
import { BusStation } from './bus-station';

/**
 */
export class RoutesBusStions {
    route_id?: number;
    route_name?: string;
    bus_stations?: BusStation[];
}
