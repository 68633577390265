/* tslint:disable */

/**
 */
export class TransactionOnline {
    from_date?: string;
    to_date?: string;
    type?: string;
    partner?: string;
}
