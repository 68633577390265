/* tslint:disable */

/**
 */
export class CategoryFrom {
    id?: number;
    type?: string;
    display_name?: string;
    parent_id?: number;
    description?: string;
    key?: string;
}
