/* tslint:disable */

/**
 */
export class Shift {
    id?: number;
    device_id?: number;
    user_id?: number;
    vehicle_id?: number;
    route_id?: number;
    collected?: number;
    shift_destroy?: number;
    started?: string;
    ended?: string;
    subdriver_id?: number;
}
