/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { filter } from "rxjs/operators/filter";

import { TicketType } from "../models/ticket-type";
import { TicketTypeForm } from "../models/ticket-type-form";
import { TicketAllocate } from "../models/ticket-allocate";
import { TicketAllocateSearch } from "../models/ticket-allocate-search";
import { TicketAllocateNewForm } from "../models/ticket-allocate-new-form";
import { TicketAllocateForm } from "../models/ticket-allocate-form";
import { TicketTypeInput } from "../models/ticket-type-input";
import { TicketSold } from "../models/ticket-sold";
import { TicketSoldNewForm } from "../models/ticket-sold-new-form";
import { TicketSoldSearch } from "../models/ticket-sold-search";
import { TicketReverse } from "../models/ticket-reverse";
import { StockSearch } from "../models/stock-search";
import { Stock } from "../models/stock";

@Injectable()
export class ManagerStockService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */

  
  managerlistStockResponse(
    params: Stock
  ): Observable<HttpResponse<StockSearch[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set("ticket_price_id", params.ticket_price_id.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/stock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: StockSearch[] = null;
        _body = _resp.body as StockSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
        StockSearch[]
        >;
      })
    );
  }

  /**
   */
   managerlistStock(
    params: Stock
  ): Observable<StockSearch[]> {
    return this.managerlistStockResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  managerlistStockCounterResponse(
    params: Stock
  ): Observable<HttpResponse<StockSearch[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set("ticket_price_id", params.ticket_price_id.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/stock/counter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: StockSearch[] = null;
        _body = _resp.body as StockSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
        StockSearch[]
        >;
      })
    );
  }

  /**
   */
   managerlistStockCounter(
    params: Stock
  ): Observable<StockSearch[]> {
    return this.managerlistStockCounterResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  managerlistStockUserResponse(
    params: Stock
  ): Observable<HttpResponse<StockSearch[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set("ticket_price_id", params.ticket_price_id.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/stock/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: StockSearch[] = null;
        _body = _resp.body as StockSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
        StockSearch[]
        >;
      })
    );
  }

  /**
   */
  managerlistStockUser(
    params: Stock
  ): Observable<StockSearch[]> {
    return this.managerlistStockUserResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  
  managerDownloadStockCounterResponse(
    params: Stock
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set("ticket_price_id", params.ticket_price_id.toString());
      if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "post",
      this.rootUrl + `/manager/stock/counter/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  //export excel
  managerDownloadStockResponse(
    params: Stock
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set("ticket_price_id", params.ticket_price_id.toString());
    let req = new HttpRequest<any>(
      "post",
      this.rootUrl + `/manager/stock/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  managerDownloadStockUserResponse(
    params: Stock
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set("ticket_price_id", params.ticket_price_id.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    let req = new HttpRequest<any>(
      "post",
      this.rootUrl + `/manager/stock/user/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

}
