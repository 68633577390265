/* tslint:disable */

/**
 */
export class ExportExcelNewForm {
    ticketPriceid?: number;
    ticketTypeid?: number;
    numberFrom?: number;
    numberTo?: number;
}
