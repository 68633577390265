/* tslint:disable */

/**
 */
export class ModuleAppCompany {
    id?: number;
    module_name?: string;
    company_name?: string;
    created_at?: string;
    updated_at?: string;
}
