/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { User } from '../models/user';
import { UserCreate } from '../models/user-create';
import { UserUpdate } from '../models/user-update';
import { UserAction } from '../models/user-action';
import { UserSearch } from '../models/user-search';
import { UserInput } from '../models/user-input';
import { UserChangePassword} from '../models/user-change-password';

@Injectable()
export class ManagerUsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListUsersResponse(params: ManagerUsersService.ManagerListUsersParams): Observable<HttpResponse<User[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    if (params.disable != null) __params = __params.set("disable", params.disable.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User[] = null;
        _body = _resp.body as User[]
        return _resp.clone({body: _body}) as HttpResponse<User[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListUsers(params: ManagerUsersService.ManagerListUsersParams): Observable<User[]> {
    return this.managerListUsersResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Created User object
   */
    /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListUsersSellerResponse(): Observable<HttpResponse<User[]>> {
      let __headers = new HttpHeaders();
      let __body: any = null;
      let req = new HttpRequest<any>(
        "GET",
        this.rootUrl + `/manager/ticket-sales/listStaff`,
        __body,
        {
          headers: __headers,
          // params: __params,
          responseType: 'json'
        });
  
      return this.http.request<any>(req).pipe(
        filter(_r => _r instanceof HttpResponse),
        map(_r => {
          let _resp = _r as HttpResponse<any>;
          let _body: User[] = null;
          _body = _resp.body as User[]
          return _resp.clone({body: _body}) as HttpResponse<User[]>;
        })
      );
    }

    managerListUsersSeller(): Observable<User[]> {
      return this.managerListUsersSellerResponse().pipe(
        map(_r => _r.body)
      );
    }
  managerListUserByRoleResponse(role_name: any): Observable<HttpResponse<User[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/users/role-name/${role_name}`,
      __body,
      {
        headers: __headers,
        // params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User[] = null;
        _body = _resp.body as User[]
        return _resp.clone({body: _body}) as HttpResponse<User[]>;
      })
    );
  }

  managerListUserByRole(role_name: any): Observable<User[]> {
    return this.managerListUserByRoleResponse(role_name).pipe(
      map(_r => _r.body)
    );
  }

    /**
     * @param body - Created User object
     */
  managerCreateUserResponse(body?: UserCreate): Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User = null;
        _body = _resp.body as User
        return _resp.clone({body: _body}) as HttpResponse<User>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerCreateUser(body?: UserCreate): Observable<User> {
    return this.managerCreateUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - update
   */
  managerUpdateUserResponse(body?: UserUpdate): Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User = null;
        _body = _resp.body as User
        return _resp.clone({body: _body}) as HttpResponse<User>;
      })
    );
  }

  /**
   * @param body - update
   */
  managerUpdateUser(body?: UserUpdate): Observable<User> {
    return this.managerUpdateUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param userId - undefined
   */
  managerGetUserResponse(userId: number): Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/users/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User = null;
        _body = _resp.body as User
        return _resp.clone({body: _body}) as HttpResponse<User>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerGetUser(userId: number): Observable<User> {
    return this.managerGetUserResponse(userId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param userId - undefined
   */
  managerDeleteUserResponse(userId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/users/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerDeleteUser(userId: number): Observable<void> {
    return this.managerDeleteUserResponse(userId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - update
   */
  managerActionUserResponse(body?: UserAction): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/users/action`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param body - update
   */
  managerActionUser(body?: UserAction): Observable<void> {
    return this.managerActionUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - Search User
   */
  managerSearchUserResponse(body?: UserSearch): Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/users/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User = null;
        _body = _resp.body as User
        return _resp.clone({body: _body}) as HttpResponse<User>;
      })
    );
  }

  /**
   * @param body - Search User
   */
  managerSearchUser(body?: UserSearch): Observable<User> {
    return this.managerSearchUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  managerListUserInputResponse(body?: UserInput): Observable<HttpResponse<User[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/user/searchbykey`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User[] = null;
        _body = _resp.body as User[]
        return _resp.clone({body: _body}) as HttpResponse<User[]>;
      })
    );
  }
  managerListUserInput(body?: UserInput): Observable<User[]> {
    return this.managerListUserInputResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerChangePasswordUserResponse(body?: UserChangePassword): Observable<HttpResponse<User[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/user/changePassword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User[] = null;
        _body = _resp.body as User[]
        return _resp.clone({body: _body}) as HttpResponse<User[]>;
      })
    );
  }

  /**
   * @param body - undefined
   */
   managerChangePasswordUser(body?: UserChangePassword): Observable<User[]> {
    return this.managerChangePasswordUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  managerListAllUserResponse(): Observable<HttpResponse<User[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/users/get_all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User[] = null;
        _body = _resp.body as User[]
        return _resp.clone({body: _body}) as HttpResponse<User[]>;
      })
    );
  }

  /**
   */
  managerListAllUser(): Observable<User[]> {
    return this.managerListAllUserResponse().pipe(
      map(_r => _r.body)
    );
  }

  managerLogoutResponse(userId?: any): Observable<HttpResponse<User>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/users/logout/${userId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: User = null;
        _body = _resp.body as User
        return _resp.clone({body: _body}) as HttpResponse<User>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerLogout(userId?: any): Observable<User> {
    return this.managerLogoutResponse(userId).pipe(
      map(_r => _r.body)
    );
  }
}

export module ManagerUsersService {
  export interface ManagerListUsersParams {
    page?: number;
    limit?: number;
    disable?: boolean;
  }
}
