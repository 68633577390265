/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { filter } from "rxjs/operators/filter";

import { TicketType } from "../models/ticket-type";
import { TicketTypeForm } from "../models/ticket-type-form";
import { TicketAllocate } from "../models/ticket-allocate";
import { TicketAllocateSearch } from "../models/ticket-allocate-search";
import { TicketAllocateNewForm } from "../models/ticket-allocate-new-form";
import { TicketAllocateForm } from "../models/ticket-allocate-form";
import { TicketTypeInput } from "../models/ticket-type-input";
import { TicketSold } from "../models/ticket-sold";
import { TicketSoldNewForm } from "../models/ticket-sold-new-form";
import { TicketSoldSearch } from "../models/ticket-sold-search";
import { TicketReverse } from "../models/ticket-reverse";
import { TicketReverseSearch } from "../models/ticket-reverse-search";

@Injectable()
export class ManagerTicketReverseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */

  managerTicketReverseResponse(body): Observable<HttpResponse<TicketReverse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-reverse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketReverseSearch[] = null;
        _body = _resp.body as TicketReverseSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketReverseSearch>;
      })
    );
  }

  /**
   * @param body - undefined
   */
   managerTicketReverse(body): Observable<TicketReverse> {
    return this.managerTicketReverseResponse(body).pipe(map(_r => _r.body));
  }
  managerlistTicketReverseResponse(
    params: TicketReverse
  ): Observable<HttpResponse<TicketReverseSearch[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-reverse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketReverseSearch[] = null;
        _body = _resp.body as TicketReverseSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
        TicketReverseSearch[]
        >;
      })
    );
  }

  /**
   */
   managerlistTicketReverse(
    params: TicketReverse
  ): Observable<TicketReverseSearch[]> {
    return this.managerlistTicketReverseResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  //export excel
  managerDownloadTicketReverseResponse(
    params: TicketReverse
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());

    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-reverse/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }
}
