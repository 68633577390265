/* tslint:disable */
import { Company } from './company';
import { Vehicle } from './vehicle';

/**
 */
export class Dashboard {
    company?: Company;
    vehicles?: Vehicle[];
}
