/* tslint:disable */

/**
 */
export class CategoryNewsForm {
    id?: number;
    name?: string;
    description?: string;
    weigth?: number;
}
