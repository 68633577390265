/* tslint:disable */

/**
 */
export class ShiftSupervisorView {
    id?: number;
    fullname?: string;
    license_plates?: string;
    route_name?: string;
    station_up?: string;
    station_down?: string;
    start_end?: string;
}
