/* tslint:disable */
import { RevenueData } from './revenue-data';

/**
 */
export class RevenueChart {
    title_chart?: string;
    shift_id?: number;
    data?: RevenueData[];
}
