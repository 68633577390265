/* tslint:disable */

/**
 */
export class Permission {
    id?: number;
    key?: string;
    created_at?: string;
    updated_at?: string;
    key_tools?: string;
    role_id?: number;
    company_id?: number;
}
