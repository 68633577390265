/* tslint:disable */

/**
 */
export class RpTicketsForm {
    from_date?: string;
    to_date?: string;
    route_id?: number;
    price_id?: number;
}
