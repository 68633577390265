/* tslint:disable */
import { Company } from './company';

/**
 */
export class GroupKeyCompanyCreate {
    name?: string;
    key?: string;
    companies?: Company[];
    type?: string;
}
