/* tslint:disable */
/**
 */
export class TicketDeleteSearch {
    seri_from?: number;
    seri_to?: number;
    ticket_price_id?: number;
    ticket_type_id?: number;
    user_id_sale?:number;
    seri_start?:string;
    seri_end?:string;
    description?:string;
}
