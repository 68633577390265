/* tslint:disable */

/**
 */
export class HistoryShiftSearch {
    date_form?: string;
    date_to?: string;
    user_id?: number;
    user_collected_id?: number;
}
