/* tslint:disable */

/**
 */
export class MembershipDetailSearch {
    search_type?: number;
    search_opt?: number;
    search_key?: string;
    rfid?: string;
    search_date?: string;
}
