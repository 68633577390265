/* tslint:disable */

/**
 */
export class RfidCard {
    id?: number;
    rfid?: string;
    barcode?: string;
    usage_type?: string;
    target_id?: number;
    created_at?: string;
    updated_at?: string;
}
