/* tslint:disable */
/**
 */
export class TicketDelete {
    from_date?: string;
    to_date?: string;
    user_id?: number;
    limit?: number;
    page?: number;
    ticket_price_id?: number;
    
}
