/* tslint:disable */

/**
 */
export class TicketAllocateForm {
    device_id?: number;
    ticket_type_id?: number;
    from_date?: string;
    to_date?: string;
}
