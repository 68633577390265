/* tslint:disable */

/**
 */
export class CardView {
    frid?: string;
    barcode?: string;
    fullname?: string;
    membership_type_card?: string;
    number_tarnsaction?: number;
    total_revenue?: number;
}
