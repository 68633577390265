/* tslint:disable */

/**
 */
export class TicketAllocateNewForm {
    ticket_type_id?: number;
    number?: number;
    created_at?:Date;
    user_id_sold?:number;
    seri_start?:number;
}
