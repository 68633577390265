/* tslint:disable */
/**
 */
export class Stock {
    from_date?:string;
    to_date?:string;
    ticket_price_id?: number;
    page?: number;
    limit?: number;
    ticket_counters_id?: number;
    user_id?: number;
}
