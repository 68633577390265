/* tslint:disable */

/**
 */
export class MembershipDetail {
    id?: number;
    company_id?: number;
    amount?: number;
    bustation_name?: string;
    user_name?: string;
    ticket_number?: string;
    created_at?: string;
}
