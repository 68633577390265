/* tslint:disable */

/**
 */
export class AppTicket {
    ticket_code?: string;
    timestamp?: number;
    duration?: number;
    startdate?: string;
    price?: number;
    moreinfo?: string;
}
