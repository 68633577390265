/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse,
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { DatabaseBackup } from '../models/database-backup';


@Injectable()
export class ManagerDatabaseBackupsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   */
  getDatabaseBackupsResponse(): Observable<HttpResponse<DatabaseBackup[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/database-backups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: DatabaseBackup[] = null;
        _body = _resp.body as DatabaseBackup[]
        return _resp.clone({body: _body}) as HttpResponse<DatabaseBackup[]>;
      })
    );
  }

  /**
   */
  getDatabaseBackups(): Observable<DatabaseBackup[]> {
    return this.getDatabaseBackupsResponse().pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param key - undefined
   */
  downloadDatabaseBackupResponse(key: string): Observable<HttpResponse<string[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/database-backups/${key}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string[] = null;
        _body = _resp.body as string[]
        return _resp.clone({body: _body}) as HttpResponse<string[]>;
      })
    );
  }

  /**
   * @param key - undefined
   */
  downloadDatabaseBackup(key: string): Observable<string[]> {
    return this.downloadDatabaseBackupResponse(key).pipe(
      map(_r => _r.body)
    );
  }}

export module ManagerDatabaseBackupsService {
}
