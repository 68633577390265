/* tslint:disable */

/**
 */
export class MachineParam {
    timestamp?: number;
    action?: string;
    subject_data?: string;
    subject_type?: string;
    user_id?: number;
}
