/* tslint:disable */

/**
 */
export class RpTripForm {
    id_tmp?: number;
    type_opt?: number;
    from_date?: string;
    to_date?: string;
    user_id?: number;
    route_id?: number;
    position?: string;
    route_name_tmp?: string;
}
