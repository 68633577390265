/* tslint:disable */

/**
 */
export class MembershipTmp {
    phone?: string;
    id?: number;
    fullname?: string;
    cmnd?: string;
    gender?: string;
    avatar?: string;
    address?: string;
    company_id?: number;
    email?: string;
    birthday?: string;
    membershiptype_id?: number;
    accept?: number;
    created_at?: string;
    updated_at?: string;
}
