/* tslint:disable */

/**
 */
export class MembershipTmpFormApp {
    company_id?: number;
    membershiptype_id?: number;
    fullname?: string;
    cmnd?: string;
    gender?: string;
    avatar?: string;
    address?: string;
    phone?: string;
    email?: string;
    birthday?: string;
}
