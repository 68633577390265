/* tslint:disable */

/**
 */
export class ActivityLogFrom {
    user_down?: string;
    action?: string;
    subject_type?: string;
    subject_data?: string;
}
