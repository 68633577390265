/* tslint:disable */

/**
 */
export class NewsForm {
    id?: number;
    category_id?: number;
    name?: string;
    url_img?: string;
    description?: string;
    content?: string;
    weigth?: number;
}
