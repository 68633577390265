/* tslint:disable */

/**
 */
export class DevModel {
    id?: number;
    name?: string;
    model?: string;
    features?: string[];
    created_at?: string;
    updated_at?: string;
}
