/* tslint:disable */

/**
 */
export class ReceiptForm {
    shifts?: number[];
    date?: string;
    date_to?: string;
    date_from?: string;
    user_id?: any;
    vehicle_id?: any;
    price_id?:string;
    hilo_id?: any;
    page?: number;
    limit?: number;
    type?: number;
    month?: string;
    type_check?: any;
    ticket_counters_id?: any;
    opt?: any;
    historical_sites_id?: any;
}
