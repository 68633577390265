/* tslint:disable */

/**
 */
export class EInvoiceSearch {
    doanhthu?: number;
    user_id?: number;
    ticket_allocate_id?: string;
    date?: string;
    fullname?: string;
}
