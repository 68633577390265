/* tslint:disable */

/**
 */
export class TicketAllocateSearch {
    identity?: string;
    ticket_price?: number;
    start_number?: number;
    end_number?: number;
    created_at?: string;
}
