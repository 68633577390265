/* tslint:disable */

/**
 */
export class SubscriptionTypeForm {
    id?: number;
    comany_id?: number;
    name?: string;
    display_name?: string;
    duration?: number;
    price?: number;
}
