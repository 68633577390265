/* tslint:disable */

/**
 */
export class DashboardDetail {
    id?: number;
    license_plates?: string;
    driver_name?: string;
    subdriver_name?: string;
    direction_name?: string;
    location_vehicle?: string;
}
