/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { filter } from "rxjs/operators/filter";

import { TicketInStock } from "../models/ticket-in-stock";

@Injectable()
export class ManagerTicketInStockService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerListTicketInStockResponse(
    params: ManagerTicketInStockService.ManagerListTicketInStockParams
  ): Observable<HttpResponse<TicketInStock[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.date != null)
      __params = __params.set("date", params.date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-in-stock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketInStock[] = null;
        _body = _resp.body as TicketInStock[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketInStock[]>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerExportTicketInStockResponse(
    params: TicketInStock
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.date != null)
      __params = __params.set("date", params.date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-in-stock/exportExcel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }
}
export namespace ManagerTicketInStockService {
  export interface ManagerListTicketInStockParams {
    page?: number;
    limit?: number;
    date?: string;
    ticket_price_id?: number;
  }
}
