/* tslint:disable */

/**
 */
export class PrepaidCardForm {
    id?: number;
    comany_id?: number;
    rfid?: string;
    balance?: number;
    barcode?: string;
    expiration_date?: string;
}
