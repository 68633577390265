export class EditEinvoiceForm {
    fkey?: string[];
    Buyer?: string;
    ComAddress?: string;
    ComName?: string;
    ComTaxCode?: string;
    ComEmail?: string;
    ComEmailCC?: string;
    Status?: number;
}
