/* tslint:disable */

/**
 */
export class Application {
    id?: number;
    comany_id?: number;
    api_key?: string;
    company_address?: string;
    company_name?: string;
    email?: string;
    url?: string;
    created_at?: string;
    updated_at?: string;
}
