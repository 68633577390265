/* tslint:disable */

/**
 */
export class RpTripView {
    user_name?: string;
    route_number?: number;
    role_name?: string;
    total_count_number?: number;
}
