/* tslint:disable */

/**
 */
export class CardMonthGroupBusStationView {
    group_bus_station?: string;
    ticket_number?: string;
    amount?: number;
    activated?: string;
}
