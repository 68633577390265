/* tslint:disable */

/**
 */
export class RfidCardUpdate {
    id?: number;
    rfid?: string;
    barcode?: string;
    usage_type?: string;
    target_id?: number;
}
