/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { Customer } from '../models/customer';

@Injectable()
export class ManagerCustomerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerListCustomersResponse(params: ManagerCustomerService.ManagerListCustomersParams): Observable<HttpResponse<Customer[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Customer[] = null;
        _body = _resp.body as Customer[]
        return _resp.clone({body: _body}) as HttpResponse<Customer[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListCustomers(params: ManagerCustomerService.ManagerListCustomersParams): Observable<Customer[]> {
    return this.managerListCustomersResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */

  managerCreateCustomerResponse(body?: Customer): Observable<HttpResponse<Customer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Customer = null;
        _body = _resp.body as Customer
        return _resp.clone({body: _body}) as HttpResponse<Customer>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerCreateCustomer(body?: Customer): Observable<Customer> {
    return this.managerCreateCustomerResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerUpdateCustomerResponse(body?: Customer): Observable<HttpResponse<Customer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/customers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Customer = null;
        _body = _resp.body as Customer
        return _resp.clone({body: _body}) as HttpResponse<Customer>;
      })
    );
  }

  /**
   * @param body - update
   */
  managerUpdateCustomer(body?: Customer): Observable<Customer> {
    return this.managerUpdateCustomerResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerDeleteCustomerResponse(customerId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/customers/${customerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerDeleteCustomer(customerId: number): Observable<void> {
    return this.managerDeleteCustomerResponse(customerId).pipe(
      map(_r => _r.body)
    );
  }

  managerListCustomersBySearchResponse(body?: any): Observable<HttpResponse<Customer[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/customers/by-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Customer = null;
        _body = _resp.body as Customer
        return _resp.clone({body: _body}) as HttpResponse<Customer[]>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerListCustomersBySearch(body?: any): Observable<Customer[]> {
    return this.managerListCustomersBySearchResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerExportListCustomersResponse(body: any): Observable<HttpResponse<string[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/customers/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string[] = null;
        _body = _resp.body as string[]
        return _resp.clone({body: _body}) as HttpResponse<string[]>;
      })
    );
  }

  /**
   * @param transactionType - undefined
   * @param rfid - undefined
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerExportListCustomers(body: any): Observable<string[]> {
    return this.managerExportListCustomersResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerGetCustomersResponse(params: any): Observable<HttpResponse<Customer[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.key_input != null) __params = __params.set("key_input", params.key_input.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/customers/by-fullname`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: Customer[] = null;
        _body = _resp.body as Customer[]
        return _resp.clone({body: _body}) as HttpResponse<Customer[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerGetCustomers(params: any): Observable<Customer[]> {
    return this.managerGetCustomersResponse(params).pipe(
      map(_r => _r.body)
    );
  }
}

export module ManagerCustomerService {
  export interface ManagerListCustomersParams {
    page?: number;
    limit?: number;
  }
}
