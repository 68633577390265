/* tslint:disable */
/**
 */
export class StockSearch {
    price?: number;
    order_code?: number;
    sign?: number;
    ve_ton_dau_ky?: number;
    serial_ton_dau_ky?:string;
    ve_nhap_trong_ky?:number;
    serial_nhap_trong_ky?:string;
    ve_xuat_trong_ky?: number;
    serial_xuat_trong_ky?:string;
    ve_ton_cuoi_ky?:number;
    serial_ton_cuoi_ky?:string;
}
