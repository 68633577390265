/* tslint:disable */

/**
 */
export class CategoryNews {
    id?: number;
    name?: string;
    description?: string;
    weigth?: number;
}
