/* tslint:disable */

/**
 */
export class MembershipTypeForm {
    id?: number;
    name?: string;
    deduction?: number;
    code?: number;
    company_id?: number;
}
