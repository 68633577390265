/* tslint:disable */

/**
 */
export class GroupKeyCompany {
    id?: number;
    name?: string;
    key?: string;
    created_at?: string;
    type?: string;
}
