/* tslint:disable */

/**
 */
export class CardMonthStaffView {
    fullname?: string;
    role_name?: string;
    route_name?: string;
    route_number?: string;
    barcode?: string;
    count_times?: number;
    total_revenue?: number;
}
