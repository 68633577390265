/* tslint:disable */

/**
 */
export class PrepaidCard {
    id?: number;
    comany_id?: number;
    rfid?: string;
    balance?: number;
    barcode?: string;
    expiration_date?: string;
    created_at?: string;
    updated_at?: string;
}
