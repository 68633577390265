export class EInvoiceForm {
    from_date?: string;
    to_date?: string;
    user_id?: number;
    vehicle_id?: number;
    status?: string;
    license?: string;
    limit?: number;
    page?: number;
    type?: any;
    ticket_type_id?: any;
}
