/* tslint:disable */

/**
 */
export class VehicleRoutePeriodForm {
    now_from_date?: string;
    now_to_date?: string;
    last_from_date?: string;
    last_to_date?: string;
    object_compare?: string;
    object_report?: string;
}
