/* tslint:disable */

/**
 */
export class ModuleAppForm {
    id?: number;
    name?: string;
    display_name?: string;
    description?: string;
}
