/* tslint:disable */

/**
 */
export class ReceiptTransaction {
    ticket_number?: string;
    type?: string;
    created_at?: string;
    amount?: number;
}
