/* tslint:disable */
import { CompanyEInvoice } from './company-einvoice';

/**
 */
export class CompanyUpdate {
    tax_code?: string;
    id?: number;
    subname?: string;
    fullname?: string;
    address?: string;
    print_at?: string;
    name?: string;
    phone?: string;
    logo?: string;
    email?: string;
    lat?: number;
    lng?: number;
    started_at?: string;
    ended_at?: string;
    e_invoice?: CompanyEInvoice;
}
