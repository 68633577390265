/* tslint:disable */

/**
 */
export class UpdateAction {
    id?: number;
    action?: string;
    subject_data?: string;
    subject_type?: string;
}
