/* tslint:disable */

/**
 */
export class CardExemption {
    membership_type?: string;
    total_ticket?: number;
    total_amount?: number;
    subject_data?: string;
}
