/* tslint:disable */

/**
 */
export class RpStaffForm {
    from_date?: string;
    to_date?: string;
    route_id?: number;
    position?: string;
    user_id?: number;
}
