/* tslint:disable */

/**
 */
export class VehicleAllView {
    license_plates?: string;
    count_tickets?: number;
    total_price_pos?: number;
    total_price_charge?: number;
    total_price_deposit?: number;
    total_revenue?: number;
}
