/* tslint:disable */

/**
 */
export class TicketTypeForm {
    sign_form?: string;
    id?: number;
    duration?: number;
    name?: string;
    description?: string;
    order_code?: string;
    sign?: string;
    comany_id?: number;
    number_km?: number;
    sale_of?: number;
    language?: string;
    limit_number?: number;
    charge_limit?: number;
    type?: number;
    price?: number;
    start_number?: number;
    unit?: any;
    amount_vat?: number;
    historical_site_limit?: number;
    gift_included?: boolean;
    time_start?: string;
    time_end?: string;
    historical_site_data?: number[];
    limit_day?: number;
    receipt?: boolean;
    is_hide?: boolean;
}
