/* tslint:disable */

/**
 */
export class VehicleActivityForm {
    type?: number;
    month?: string;
    quarter?: number;
    year?: number;
    route_id?: number;
}
