/* tslint:disable */

/**
 */
export class CompanyEInvoiceHilo {
    passenger_ticket?: boolean;
    goods_ticket?: boolean;
    url?: string;
    username?: string;
    password?: string;
    taxcode?: string;
    pattern?: string;
    serial?: string;
    assign_vehicles?: boolean;
    sell_by_user?: boolean;
    ticket_counters?: boolean;
}
