/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { HistoricalSites } from '../models';

@Injectable()
export class ManagerHistoricalSitesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerHistoricalSitesResponse(params: ManagerHistoricalSitesService.ManagerHistoricalSitesParams): Observable<HttpResponse<HistoricalSites[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    if (params.page != null) __params = __params.set("key_input", params.key_input.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/historical-sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: HistoricalSites[] = null;
        _body = _resp.body as HistoricalSites[]
        return _resp.clone({body: _body}) as HttpResponse<HistoricalSites[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerHistoricalSites(params: ManagerHistoricalSitesService.ManagerHistoricalSitesParams): Observable<HistoricalSites[]> {
    return this.managerHistoricalSitesResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  managerHistoricalSitesGroupResponse(params: ManagerHistoricalSitesService.ManagerHistoricalSitesParams): Observable<HttpResponse<HistoricalSites[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/historical-sites-group`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: HistoricalSites[] = null;
        _body = _resp.body as HistoricalSites[]
        return _resp.clone({body: _body}) as HttpResponse<HistoricalSites[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerHistoricalSitesGroup(params: ManagerHistoricalSitesService.ManagerHistoricalSitesParams): Observable<HistoricalSites[]> {
    return this.managerHistoricalSitesGroupResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */

  managerCreateHistoricalSitesResponse(body?: HistoricalSites): Observable<HttpResponse<HistoricalSites>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/historical-sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: HistoricalSites = null;
        _body = _resp.body as HistoricalSites
        return _resp.clone({body: _body}) as HttpResponse<HistoricalSites>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerCreateHistoricalSites(body?: HistoricalSites): Observable<HistoricalSites> {
    return this.managerCreateHistoricalSitesResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerUpdateHistoricalSitesResponse(body?: HistoricalSites): Observable<HttpResponse<HistoricalSites>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/historical-sites/${body.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: HistoricalSites = null;
        _body = _resp.body as HistoricalSites
        return _resp.clone({body: _body}) as HttpResponse<HistoricalSites>;
      })
    );
  }

  /**
   * @param body - update
   */
  managerUpdateHistoricalSites(body?: HistoricalSites): Observable<HistoricalSites> {
    return this.managerUpdateHistoricalSitesResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerDeleteHistoricalSitesResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/historical-sites/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerDeleteHistoricalSites(id: number): Observable<void> {
    return this.managerDeleteHistoricalSitesResponse(id).pipe(
      map(_r => _r.body)
    );
  }
}

export module ManagerHistoricalSitesService {
  export interface ManagerHistoricalSitesParams {
    page?: number;
    limit?: number;
    key_input?: string;
  }
}
