/* tslint:disable */

/**
 */
export class NotifyType {
    id?: number;
    name?: string;
    key?: string;
    route_link?: string;
    url_img?: string;
    created_at?: string;
    updated_at?: string;
}
