/* tslint:disable */

/**
 */
export class PermissionForm {
    id?: number;
    key?: string;
    key_tools?: string;
    role_id?: number;
    company_id?: number;
}
