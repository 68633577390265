/* tslint:disable */

/**
 */
export class TicketDestroyForm {
    from_date?: string;
    to_date?: string;
    accept?: number;
    ticket_id?:number;
}
