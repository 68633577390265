/* tslint:disable */

/**
 */
export class RpVehicleForm {
    from_date?: string;
    to_date?: string;
    route_id?: number;
    vehicle_id?: number;
}
