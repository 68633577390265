/* tslint:disable */

/**
 */
export class TicketView {
    price?: number;
    start_number?: number;
    end_number?: number;
    count_sale?: number;
}
