/* tslint:disable */
/**
 */
export class Customer {
    id?: number;
    company_id?: number;
    email?: string;
    email_cc?: string;
    fullname?: string;
    address?: string;
    phone?: string;
    tax_code?: string;
    created_at?: string;
    updated_at?: string;
    page?: number;
    limit?: number;
}
