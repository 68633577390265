/* tslint:disable */
/**
 */
export class HistoricalSites {
    id?: number;
    company_id?: number;
    name?: string;
    position?: string;
    user_id?: number[];
    historical_sites_group_id?: number;
    created_at?: string;
    updated_at?: string;
    page?: number;
    limit?: number;
}
