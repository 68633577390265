/* tslint:disable */
/**
 */
export class TicketCounters {
    id?: number;
    company_id?: number;
    name?: string;
    position?: string;
    created_at?: string;
    updated_at?: string;
    page?: number;
    limit?: number;
}
