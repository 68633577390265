export class TicketSold{
    id?: number;
    company_id?: number;
    device_id?: number;
    ticket_type_id?: number;
    ticket_price_id?: number;
    start_number?: number;
    end_number?: number;
    ticket_price?: number;
    current_number?: number;
    status_warning?: number;
    message?:number;
    amount?:number;
}