/* tslint:disable */

/**
 */
export class MachineActivity {
    timestamp?: number;
    action?: string;
    subject_data?: string;
    subject_type?: string;
}
