/* tslint:disable */

/**
 */
export class CardMonthStaffForm {
    from_date?: string;
    to_date?: string;
    user_id?: number;
    route_id?: number;
    barcode?: string;
}
