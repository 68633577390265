/* tslint:disable */
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders,
  HttpParams
} from "@angular/common/http";
import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { filter } from "rxjs/operators/filter";

import { TicketType } from "../models/ticket-type";
import { TicketTypeForm } from "../models/ticket-type-form";
import { TicketAllocate } from "../models/ticket-allocate";
import { TicketAllocateSearch } from "../models/ticket-allocate-search";
import { TicketAllocateNewForm } from "../models/ticket-allocate-new-form";
import { TicketAllocateForm } from "../models/ticket-allocate-form";
import { TicketTypeInput } from "../models/ticket-type-input";
import { TicketSold } from "../models/ticket-sold";
import { TicketSoldNewForm } from "../models/ticket-sold-new-form";
import { TicketSoldSearch } from "../models/ticket-sold-search";
@Injectable()
export class ManagerTicketTypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerlistTicketTypesResponse(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.all != null)
      __params = __params.set("all", params.all.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticketTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType[] = null;
        _body = _resp.body as TicketType[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketType[]>;
      })
    );
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerlistTicketTypes(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<TicketType[]> {
    return this.managerlistTicketTypesResponse(params).pipe(map(_r => _r.body));
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketTypeResponse(
    body?: TicketTypeForm
  ): Observable<HttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType = null;
        _body = _resp.body as TicketType;
        return _resp.clone({ body: _body }) as HttpResponse<TicketType>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketType(body?: TicketTypeForm): Observable<TicketType> {
    return this.managerCreateTicketTypeResponse(body).pipe(map(_r => _r.body));
  }
  /**
   * @param body - undefined
   */
  managerUpdateTicketTypeResponse(
    body?: TicketTypeForm
  ): Observable<HttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/ticketTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType = null;
        _body = _resp.body as TicketType;
        return _resp.clone({ body: _body }) as HttpResponse<TicketType>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerUpdateTicketType(body?: TicketTypeForm): Observable<TicketType> {
    return this.managerUpdateTicketTypeResponse(body).pipe(map(_r => _r.body));
  }
  /**
   * @param ticketTypeId - undefined
   */
  managerGetTicketTypeByIdResponse(
    ticketTypeId: number
  ): Observable<HttpResponse<TicketType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticketTypes/${ticketTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType = null;
        _body = _resp.body as TicketType;
        return _resp.clone({ body: _body }) as HttpResponse<TicketType>;
      })
    );
  }

  /**
   * @param ticketTypeId - undefined
   */
  managerGetTicketTypeById(ticketTypeId: number): Observable<TicketType> {
    return this.managerGetTicketTypeByIdResponse(ticketTypeId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param ticketTypeId - undefined
   */
  managerDeleteticketTypeResponse(
    ticketTypeId: number
  ): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/ticketTypes/${ticketTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;

        return _resp.clone({ body: _body }) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param ticketTypeId - undefined
   */
  managerDeleteticketType(ticketTypeId: number): Observable<void> {
    return this.managerDeleteticketTypeResponse(ticketTypeId).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  managerlistTicketAllocatesResponse(): Observable<
    HttpResponse<TicketAllocate[]>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticketTypes/ticketAllocates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocate[] = null;
        _body = _resp.body as TicketAllocate[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketAllocate[]>;
      })
    );
  }

  /**
   */
  managerlistTicketAllocates(): Observable<TicketAllocate[]> {
    return this.managerlistTicketAllocatesResponse().pipe(map(_r => _r.body));
  }
  /**
   * @param body - undefined
   */

  managerlistTicketAllSoldResponse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketSold[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.user_id_search != null)
      __params = __params.set(
        "user_id_search",
        params.user_id_search.toString()
      );
    if (params.from != null)
      __params = __params.set("date_from", params.from.toString());
    if (params.to != null)
      __params = __params.set("date_to", params.to.toString());
    if (params.price_id != null)
      __params = __params.set("price_id", params.price_id.toString());
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.license != null)
      __params = __params.set("license", params.license.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold[] = null;
        _body = _resp.body as TicketSold[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold[]>;
      })
    );
  }

  /**
   */
  managerlistTicketAllSold(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<TicketSold[]> {
    return this.managerlistTicketAllSoldResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  managerCreateTicketAllocateResponse(
    body?: TicketAllocateNewForm
  ): Observable<HttpResponse<TicketAllocateSearch>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketTypes/ticketAllocates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocateSearch = null;
        _body = _resp.body as TicketAllocateSearch;
        return _resp.clone({ body: _body }) as HttpResponse<
          TicketAllocateSearch
        >;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketAllocate(
    body?: TicketAllocateNewForm
  ): Observable<TicketAllocateSearch> {
    return this.managerCreateTicketAllocateResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  managerlistTicketAllocateSearchsResponse(
    body?: TicketAllocateForm
  ): Observable<HttpResponse<TicketAllocateSearch[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketTypes/ticketAllocateSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocateSearch[] = null;
        _body = _resp.body as TicketAllocateSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
          TicketAllocateSearch[]
        >;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerlistTicketAllocateSearchs(
    body?: TicketAllocateForm
  ): Observable<TicketAllocateSearch[]> {
    return this.managerlistTicketAllocateSearchsResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  managerGetSignTicketAllocateResponse(): Observable<HttpResponse<object[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticketTypes/ticketAllocates/sign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: object[] = null;
        _body = _resp.body as object[];
        return _resp.clone({ body: _body }) as HttpResponse<object[]>;
      })
    );
  }


  managerGetTotalTicketAllocateResponse(
    body?: any
  ): Observable<HttpResponse<any[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketTypes/ticketAllocates/total`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any[]>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any[]>;
        let _body: any = null;
        _body = _resp.body as any[];
        return _resp.clone({ body: _body }) as HttpResponse<any[]>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerGetTotalTicketAllocate(
    body?: any
  ): Observable<any[]> {
    return this.managerGetTotalTicketAllocateResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   */
  managerGetSignTicketAllocate(): Observable<object[]> {
    return this.managerGetSignTicketAllocateResponse().pipe(map(_r => _r.body));
  }
  /**
   * @param typeParam - undefined
   */

  managerListTicketTypesByTypeParamResponse(
    typeParam: number
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticketTypes/types/${typeParam}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType[] = null;
        _body = _resp.body as TicketType[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketType[]>;
      })
    );
  }

  /**
   * @param typeParam - undefined
   */
  managerListTicketTypesByTypeParam(
    typeParam: number
  ): Observable<TicketType[]> {
    return this.managerListTicketTypesByTypeParamResponse(typeParam).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  managerSearchTicketTypesByKeyWordResponse(
    body?: TicketTypeInput
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketTypes/searchbykeyword`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType[] = null;
        _body = _resp.body as TicketType[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketType[]>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerSearchTicketTypesByKeyWord(
    body?: TicketTypeInput
  ): Observable<TicketType[]> {
    return this.managerSearchTicketTypesByKeyWordResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerDownloadTicketResponse(
    ticketPriceId: number,
    ticketTypeId: number,
    numberFrom: number,
    numberTo: number
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/manager/ticketTypesdownload/${ticketPriceId}/${ticketTypeId}/${numberFrom}/${numberTo}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  managerDownloadTicketSaleResponse(
    date_from: string,
    date_to: string,
    vehicle_id: string,
    ticket_price_id: number,
    ticket_counters_id: number

  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/manager/ticketSaledownload/${date_from}/${date_to}/${vehicle_id}/${ticket_price_id}/${ticket_counters_id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param ticketTypeId - undefined
   */
  /**
   * @param price - undefined
   */
  managerDownloadTicket(
    ticketPriceId: number,
    ticketTypeId: number,
    numberFrom: number,
    numberTo: number
  ): Observable<string> {
    return this.managerDownloadTicketResponse(
      ticketPriceId,
      ticketTypeId,
      numberFrom,
      numberTo
    ).pipe(map(_r => _r.body));
  }

  managerDownloadTicketSale(
    date_from: string,
    date_to: string,
    vehicle_id: string,
    ticket_price_id: number,
    ticket_counters_id: number
  ): Observable<string> {
    return this.managerDownloadTicketSaleResponse(
      date_from,
      date_to,
      vehicle_id,
      ticket_price_id,
      ticket_counters_id
    ).pipe(map(_r => _r.body));
  }

  managerlistTicketPricesResponse(
    body?: TicketAllocateForm
  ): Observable<HttpResponse<TicketAllocateSearch[]>> {
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales/listPrice`,
      __body,
      {
        headers: __headers,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocateSearch[] = null;
        _body = _resp.body as TicketAllocateSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
        TicketAllocateSearch[]
        >;
      })
    );
  }

  managerlistTicketPricesActiveResponse(body?: any): Observable<HttpResponse<any[]>> {
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales/listPrice/active`,
      __body,
      {
        headers: __headers,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any[] = null;
        _body = _resp.body as any[];
        return _resp.clone({ body: _body }) as HttpResponse<
        any[]
        >;
      })
    );
  }

  managerlistTicketPricesOnlineResponse(
    body?: TicketAllocateForm
  ): Observable<HttpResponse<any[]>> {
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-online/list-prices`,
      __body,
      {
        headers: __headers,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any[] = null;
        _body = _resp.body as any[];
        return _resp.clone({ body: _body }) as HttpResponse<
        any[]
        >;
      })
    );
  }


  managerlistTicketPricesForTicketViewResponse(
    body?: TicketAllocateForm
  ): Observable<HttpResponse<TicketAllocateSearch[]>> {
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales/listPrice/ticketView`,
      __body,
      {
        headers: __headers,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocateSearch[] = null;
        _body = _resp.body as TicketAllocateSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
          TicketAllocateSearch[]
        >;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerlistTicketPrices(
    body?: TicketAllocateForm
  ): Observable<TicketAllocateSearch[]> {
    return this.managerlistTicketAllocateSearchsResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - undefined
   */
  managerlistTicketPricesSearchsResponse(
    body?: TicketAllocateForm
  ): Observable<HttpResponse<TicketAllocateSearch[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketTypes/ticketAllocateSearch`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocateSearch[] = null;
        _body = _resp.body as TicketAllocateSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<
          TicketAllocateSearch[]
        >;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerlistTicketPricesSearchs(
    body?: TicketAllocateForm
  ): Observable<TicketAllocateSearch[]> {
    return this.managerlistTicketPricesSearchsResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   */
  showTicketActiveResonse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketSold[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales/listTicketTypeAll`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold[] = null;
        _body = _resp.body as TicketSold[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold[]>;
      })
    );
  }

  /**
   */
  showTicketTypeAssignResonse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketSold[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign/ticketTypeForAssign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold[] = null;
        _body = _resp.body as TicketSold[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold[]>;
      })
    );
  }

  showTicketTypeAssignUserResonse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketSold[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign-user/ticketTypeForAssign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold[] = null;
        _body = _resp.body as TicketSold[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold[]>;
      })
    );
  }
  /**
   * @param params- undefined
   */
  showTicketActive(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<TicketAllocate[]> {
    return this.showTicketActiveResonse(params).pipe(map(_r => _r.body));
  }
  /**
   * @param body - undefined
   */
  /**
   */
  showTicketInactiveResonse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketSold[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.ticket_type_id != null)
      __params = __params.set(
        "ticket_type_id",
        params.ticket_type_id.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales/listSeriInactive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold[] = null;
        _body = _resp.body as TicketSold[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold[]>;
      })
    );
  }

  /**
   * @param typeParam - undefined
   */
  showTicketInactive(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<TicketAllocate[]> {
    return this.showTicketInactiveResonse(params).pipe(map(_r => _r.body));
  }
  /**
   * @param body - undefined
   */
  managerCreateTicketSoldResponse(
    body?: TicketSoldNewForm
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-sales/active`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketsSoldResponse(body): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-sales/activeMore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  deleteTicketSold(body) {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-sales/inactive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body = _resp.body;
        return _resp.clone({ body: _body }) as HttpResponse<any>;
      })
    );
  }

  importFileTicketSold(body): Observable<any> {
    let token = localStorage.getItem("token");
    let bearer = "Bearer " + token;
    return this.http.post(this.rootUrl + "/manager/ticket-sales/import", body, {
      headers: { Authorization: bearer },
      reportProgress: true,
      observe: "events"
    });
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketsForDeviceResponse(
    body
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-assign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketSold(body?: TicketSoldNewForm): Observable<TicketSold> {
    return this.managerCreateTicketSoldResponse(body).pipe(map(_r => _r.body));
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketSolds(body): Observable<TicketSold> {
    return this.managerCreateTicketsSoldResponse(body).pipe(map(_r => _r.body));
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketForDevice(body): Observable<TicketSold> {
    return this.managerCreateTicketsForDeviceResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - undefined
   */
  managerInactiveTicketSoldResponse(
    body?: TicketSoldNewForm
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-sales/inactive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  /**
   * @param body - undefined
   */
  managerInactiveTicketSold(body?: TicketSoldNewForm): Observable<TicketSold> {
    return this.managerInactiveTicketSoldResponse(body).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerlistTicketSellTypesResponse(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-sales/listTicketType`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketType[] = null;
        _body = _resp.body as TicketType[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketType[]>;
      })
    );
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerlistTicketSellTypes(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<TicketType[]> {
    return this.managerlistTicketSellTypesResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param body - undefined
   */
  /**
   */
  managerlistTicketAssignResponse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.license != null)
      __params = __params.set("license", params.license.toString());
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );
      if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_counters_id",
        params.ticket_counters_id.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocate[] = null;
        _body = _resp.body as TicketAllocate[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketAllocate[]>;
      })
    );
  }

  /**
   */
  managerlistTicketAssign(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<TicketType[]> {
    return this.managerlistTicketAssignResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  managerlistTicketAssignUserResponse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.license != null)
      __params = __params.set("license", params.license.toString());
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocate[] = null;
        _body = _resp.body as TicketAllocate[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketAllocate[]>;
      })
    );
  }

  /**
   */
  managerlistTicketAssignUser(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<TicketType[]> {
    return this.managerlistTicketAssignUserResponse(params).pipe(
      map(_r => _r.body)
    );
  }  
  
  managerlistTicketAssignTransferResponse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketType[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.page != null)
      __params = __params.set("page", params.page.toString());
    if (params.limit != null)
      __params = __params.set("limit", params.limit.toString());
    if (params.license != null)
      __params = __params.set("license", params.license.toString());
    if (params.from_date != null)
      __params = __params.set("from_date", params.from_date.toString());
    if (params.to_date != null)
      __params = __params.set("to_date", params.to_date.toString());
    if (params.ticket_price_id != null)
      __params = __params.set(
        "ticket_price_id",
        params.ticket_price_id.toString()
      );
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign-transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketAllocate[] = null;
        _body = _resp.body as TicketAllocate[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketAllocate[]>;
      })
    );
  }

  /**
   */
  managerlistTicketAssignTransfer(
    params: ManagerTicketTypesService.ManagerlistTicketTypesParams
  ): Observable<TicketType[]> {
    return this.managerlistTicketAssignTransferResponse(params).pipe(
      map(_r => _r.body)
    );
  }  

  showTicketTypeAssignTransferResponse(
    params: ManagerTicketTypesService.ManagerlistTicketSoldParams
  ): Observable<HttpResponse<TicketSold[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.user_id != null)
      __params = __params.set("user_id", params.user_id.toString());
    if (params.vehicle_id != null)
      __params = __params.set("vehicle_id", params.vehicle_id.toString());
    if (params.ticket_counters_id != null)
      __params = __params.set("ticket_counters_id", params.ticket_counters_id.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign-transfer/ticketTypeForAssign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold[] = null;
        _body = _resp.body as TicketSold[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold[]>;
      })
    );
  }

  /**
   * @param ticketassignId - undefined
   */
  getTicketAssignByIdResponse(
    ticketassignId: number
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign/by-id/${ticketassignId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold = null;
        _body = _resp.body as TicketSold;
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold>;
      })
    );
  }

  /**
   * @param ticketassignId - undefined
   */
  getTicketAssignById(ticketassignId: number): Observable<TicketSold> {
    return this.getTicketAssignByIdResponse(ticketassignId).pipe(
      map(_r => _r.body)
    );
  }

  deleteTicketAssignUserByIdReponse(
    ticketassignId: number
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/ticket-assign-user/${ticketassignId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold = null;
        _body = _resp.body as TicketSold;
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold>;
      })
    );
  }

  /**
   * @param ticketassignId - undefined
   */
  deleteTicketAssignUserById(ticketassignId: number): Observable<TicketSold> {
    return this.deleteTicketAssignUserByIdReponse(ticketassignId).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - update
   */
  updateTicketAssignResponse(
    body?: TicketSold
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/ticket-assign/edit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold = null;
        _body = _resp.body as TicketSold;
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold>;
      })
    );
  }

  /**
   * @param body - update
   */
  updateTicketAssign(body?: TicketSold): Observable<TicketSold> {
    return this.updateTicketAssignResponse(body).pipe(map(_r => _r.body));
  }

  updateTicketAssignUserResponse(
    body?: TicketSold
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/ticket-assign-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSold = null;
        _body = _resp.body as TicketSold;
        return _resp.clone({ body: _body }) as HttpResponse<TicketSold>;
      })
    );
  }

  /**
   * @param body - update
   */
  updateTicketAssignUser(body?: TicketSold): Observable<TicketSold> {
    return this.updateTicketAssignUserResponse(body).pipe(map(_r => _r.body));
  }

  /**
   * @param price - undefined
   */
  exportTicket(
    ticketPriceId: number,
    ticketTypeId: number,
    numberFrom: number,
    numberTo: number
  ): Observable<string> {
    return this.exportTicketResponse(
      ticketPriceId,
      ticketTypeId,
      numberFrom,
      numberTo
    ).pipe(map(_r => _r.body));
  }

  exportTicketResponse(
    ticketPriceId: number,
    ticketTypeId: number,
    numberFrom: number,
    numberTo: number
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/manager/ticketTypesexport/${ticketPriceId}/${ticketTypeId}/${numberFrom}/${numberTo}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  getTicketInfoByTypeAndSerial(
    ticketTypeId: number,
    serial: any,
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl +
        `/manager/tickets/info/${ticketTypeId}/${serial}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param body - export Device object
   */
  downloadTicketResponse(
    body?: TicketAllocate
  ): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticketType/download`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "text"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  /**
   * @param body - export Device object
   */
  downloadTicket(body?: TicketAllocate): Observable<string> {
    return this.downloadTicketResponse(body).pipe(map(_r => _r.body));
  }

  managerCreateTicketAssign(body): Observable<TicketSold> {
    return this.managerCreateTicketsAssignResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketsAssignResponse(
    body
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-assign/create-2`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  managerCreateTicketAssignUser(body): Observable<TicketSold> {
    return this.managerCreateTicketsAssignUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketsAssignUserResponse(
    body
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-assign-user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  managerCreateTicketAssignTreasurer(body): Observable<TicketSold> {
    return this.managerCreateTicketAssignTreasurerResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - undefined
   */
  managerCreateTicketAssignTreasurerResponse(
    body
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-assign-treasurer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }

  managerDownloadTicketAssignTreasurerResponse(): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/ticket-assign-treasurer/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "blob"
      }
    );
    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string = null;
        _body = _resp.body as string;
        return _resp.clone({ body: _body }) as HttpResponse<string>;
      })
    );
  }

  managerTransferTicketAssignUser(body): Observable<TicketSold> {
    return this.managerTransferTicketAssignUserResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  /**
   * @param body - undefined
   */
  managerTransferTicketAssignUserResponse(
    body
  ): Observable<HttpResponse<TicketSold>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/ticket-assign-transfer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: "json"
      }
    );

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TicketSoldSearch[] = null;
        _body = _resp.body as TicketSoldSearch[];
        return _resp.clone({ body: _body }) as HttpResponse<TicketSoldSearch>;
      })
    );
  }
}

export namespace ManagerTicketTypesService {
  export interface ManagerlistTicketTypesParams {
    page?: number;
    limit?: number;
    all?: number;
  }
  export interface ManagerlistTicketSoldParams {
    page?: number;
    limit?: number;
    user_id_search?: number;
    from?: string;
    to?: string;
    price_id?: number;
    ticket_type_id?: string;
    vehicle_id?: string;
    user_id?: number;
    license?: string;
    from_date?: string;
    to_date?: string;
    ticket_price_id?: number;
    ticket_counters_id?: number;
  }
}
