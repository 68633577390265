/* tslint:disable */

/**
 */
export class RpDailyForm {
    from_date?: string;
    to_date?: string;
    type_date_search?: number;
    route_id?: number;
}
