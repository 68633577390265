/* tslint:disable */

/**
 */
export class DevModelForm {
    id?: number;
    name?: string;
    model?: string;
    features?: string[];
}
